/* eslint-disable react/prop-types */
import { Box, Card, Grid, Rating, Typography } from '@mui/material'
import React from 'react'
import moment from 'moment'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import Palette from '../../theme/palette'

const Moreinformation = ({ data }) => {
    // open instagram
    const handleInsta = (link) => {
        const url = `https://www.instagram.com/${link}`
        window.open(url)
    }

    // open Twitter
    const handleTwitter = (link) => {
        const url = `https://twitter.com/${link}`
        window.open(url)
    }

    return (
        <div>
            <Card style={{borderTopLeftRadius:"0px",borderTopRightRadius:"0px"}}>
                <Box p={3}>
                    <Grid container display="flex" spacing={4}>
                        <Grid item xs={12} sm={12}>
                            <Typography variant="h4" >Account Information :-</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} >
                            <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} pb={2}>
                                <Typography variant="body1">Account Open Date :</Typography>
                                <Typography variant="body2" color={Palette.grey[600]}>{data?.AccountOpenDate ? data?.AccountOpenDate : "--"}</Typography>
                            </Grid>
                            <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                                <Typography variant="body1">Coordinator Name :</Typography>
                                <Typography variant="body2" color={Palette.grey[600]}>{data?.coordinatorName ? data?.coordinatorName : "--"}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} pb={2}>
                                <Typography variant="body1">Team Leader Name :</Typography>
                                
                                <Typography variant="body2" color={Palette.grey[600]}>{data?.teamLeaderName ? data?.teamLeaderName : "--"}</Typography>
                            
            
                            </Grid>
                            
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <Typography variant="h4" >Client ID Details:-</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>

                            <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} pb={2}>
                                <Typography variant="body1">Client ID :</Typography>
                                <Typography variant="body2" color={Palette.grey[600]}>{data?.clientId ? data?.clientId : "--"}</Typography>
                            </Grid>

                           
                        </Grid>

                        <Grid item xs={12} sm={6}>

                        <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                                <Typography variant="body1">Client Generation Date :</Typography>
                                <Typography variant="body2" color={Palette.grey[600]}>{data?.clientGenerationDate ? data?.clientGenerationDate : "--"}</Typography>
                            </Grid>

                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <Typography variant="h4" >First Trade Details :-</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} pb={2}>
                                <Typography variant="body1">First Trade Date :</Typography>
                                <Typography variant="body2" color={Palette.grey[600]}>{data?.firstTradeDate ? data?.firstTradeDate : "--"}</Typography>
                            </Grid>
                            <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                                <Typography variant="body1">First Trade By :</Typography>
                                <Typography variant="body2" color={Palette.grey[600]}>{data?.firstTradeBy ? data?.firstTradeBy : "--"} </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            
                        <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} pb={2}>
                                <Typography variant="body1"> Days For First Trade : </Typography>
                                <Typography variant="body2" color={Palette.grey[600]}>{data?.daysForFirstTrade ? data?.daysForFirstTrade : "--"}</Typography>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <Typography variant="h4" >Full Trade Details :-</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} pb={2}>
                                <Typography variant="body1">Full Trade Date :</Typography>
                                <Typography variant="body2" color={Palette.grey[600]}>{data?.fullTradeDate ? data?.fullTradeDate : "--"}</Typography>
                            </Grid>
                            <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                                <Typography variant="body1">Full Trade By :</Typography>
                                <Typography variant="body2" color={Palette.grey[600]}>{data?.fullTradeBy ? data?.fullTradeBy : "--"} </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            
                        <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} pb={2}>
                                <Typography variant="body1"> Days For Full Trade : </Typography>
                                <Typography variant="body2" color={Palette.grey[600]}>{data?.daysForFullTrade ? data?.daysForFullTrade : "--"}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography variant="h4" >Application Information :-</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} pb={2}>
                                <Typography variant="body1">Application Number :</Typography>
                                <Typography variant="body2" color={Palette.grey[600]}>{data?.ApplicationNumber ? data?.ApplicationNumber : "--"}</Typography>
                            </Grid>
                            </Grid>

                        <Grid item xs={12} sm={12}>
                            <Typography variant="h4" >Fund Information :-</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} pb={2}>
                                <Typography variant="body1">Fund Added Date :</Typography>
                                <Typography variant="body2" color={Palette.grey[600]}>{data?.fundAddDate ? data?.fundAddDate : "--"}</Typography>
                            </Grid>
                            <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                                <Typography variant="body1"> Fund Amount : </Typography>
                                <Typography variant="body2" color={Palette.grey[600]}><span style={{ marginLeft: "3px" }}>&#8377;</span>{data?.fundAdd ? data?.fundAdd : "--"}</Typography>
                            
                            </Grid>
                           
                           
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} pb={2}>
                                <Typography variant="body1">Fund Added By :</Typography>
                                <Typography variant="body2" color={Palette.grey[600]}>{data?.fundAddedBy? data?.fundAddedBy : "--"} </Typography>
                            </Grid>
                           
                            
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <Typography variant="h4">Brokerage :-</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} pb={2}>
                                <Typography variant="body1"> Brokerage Amount : </Typography>
                                <Typography variant="body2" color={Palette.grey[600]}><span style={{ marginLeft: "3px" }}>&#8377;</span>{data?.Brokerage ? data?.Brokerage : "--"}</Typography>
                                 </Grid>
                        </Grid>
                        
                       
                    </Grid>
                </Box>
            </Card>
        </div >
    )
}

export default Moreinformation
