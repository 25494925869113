/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState } from 'react';
// @mui
import { Card, Stack, Button, Container, Typography, Box } from '@mui/material';
// components
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridToolbar, GridToolbarContainer } from '@mui/x-data-grid';
import { Clear, DeleteOutline } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';


import Iconify from '../../components/iconify';
// sections
// mock
import AddLead from './AddUser'
import { apiget, deleteManyApi } from '../../service/api';
import DeleteModel from '../../components/Deletemodle'
import TableStyle from '../../components/TableStyle'
// import EditModel from './Edit'
// ----------------------------------------------------------------------

function CustomToolbar({ selectedRowIds, fetchdata }) {
  const [opendelete, setOpendelete] = useState(false);
  const [userAction, setUserAction] = useState(null);

  const handleCloseDelete = () => setOpendelete(false)

  const handleOpenDelete = () => setOpendelete(true)
  
  const userObj = JSON.parse(localStorage.getItem('user'))
  const deleteManyLead = async (data) => {
    const result = await deleteManyApi('lead/deletemany', data)
    fetchdata()
    setUserAction(result)
    handleCloseDelete();
  }

  useEffect(() => {
    setUserAction(userAction)
  }, [userAction])

  return (
    <GridToolbarContainer>
      <GridToolbar />
      {/* {selectedRowIds && selectedRowIds.length > 0 && <Button variant="text" sx={{ textTransform: 'capitalize' }} startIcon={<DeleteOutline />} onClick={handleOpenDelete}>Delete</Button>} */}
      {/* <DeleteModel opendelete={opendelete} handleClosedelete={handleCloseDelete} deletedata={deleteManyLead} id={selectedRowIds} /> */}
    </GridToolbarContainer>
  );
}

const Lead = () => {


  const [userAction, setUserAction] = useState(null);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [leadData, setLeadData] = useState([]);
  const [id, setId] = useState('')
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const navigate = useNavigate()

  const userid = localStorage.getItem('user_id');
  const userRole = localStorage.getItem("userRole");

  // open edit model
  const handleOpenEdit = () => setOpenEdit(true);;
  const handleCloseEdit = () => setOpenEdit(false);

  const handleOpenAdd = () => setOpenAdd(true);
  const handleCloseAdd = () => setOpenAdd(false);



  const columns = [
    {
      field: "clientName",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell name-column--cell--capitalize",
      renderCell: (params) => {
        const handleFirstNameClick = () => {
          navigate(`/dashboard/leadUser/viewUser/${params.row._id}`)
        };

        return (
          <Box onClick={handleFirstNameClick}>
            {params.value}
          </Box>
        );
      }
    },
    
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      flex: 1,
      
    },
    {
      field: "emailAddress",
      headerName: "Email Address",
      flex: 1,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      flex: 1,
      
    },
    {
      field: "emailAddress",
      headerName: "Email Address",
      flex: 1,
    },
    {
      field: "coordinatorName",
      headerName: "Agent",
      flex: 1,
    },
    {
      field: "teamLeaderName",
      headerName: "Team L Name",
      flex: 1,
    },
    {
      field: "clientGenerationDate",
      headerName: "CGD",
      flex: 1,
    },
    {
      field: "regDate",
      headerName: "Registration Date",
      flex: 1,

   
     renderCell: (params) => {
      const leadStatus = params.value;
    
      const cellStyle = {
        color: 'black',
        fontWeight: 'normal',
      };
    
      if (leadStatus === 'Leads Under Review') {
        cellStyle.color = 'green';
        cellStyle.fontWeight = 'bold';
      } else if (leadStatus === 'Ready For Trade') {
        cellStyle.color = '#FEDC56';
        cellStyle.fontWeight = 'bold';
      } else if (leadStatus === '1st Trade') {
        cellStyle.color = 'orange';
        cellStyle.fontWeight = 'bold';
      }
      else if (leadStatus === '2nd Trade') {
        cellStyle.color = '#191970';
        cellStyle.fontWeight = 'bold';
      }
    
      return <div style={cellStyle}>{leadStatus}</div>;
    },
  },

    // {
    //   field: "action",
    //   headerName: "Action",
    //   flex: 1,
    //   // eslint-disable-next-line arrow-body-style
    //   renderCell: (params) => {
    //     const handleFirstNameClick = async (data) => {
    //       setId(data)
    //       handleOpenEdit();
    //     };
    //     return (
    //       <>
    //         {/* <EditModel open={openEdit} handleClose={handleCloseEdit} id={id} fetchLead={fetchdata} /> */}
    //         <Button variant='text' size='small' color='primary' onClick={() => handleFirstNameClick(params?.row?._id)}><EditIcon /></Button>
    //         <Button variant='text' size='small' color='primary' onClick={() => handleFirstNameClick(params?.row?._id)}><ClearIcon /></Button>
            
    //       </>
    //     );
    //   }
    // },
  ];


  // const fetchdata = async () => {
  //   const result = await apiget( `lead/list`)
    
  //   if (result && result.status === 200) {
  //     setLeadData(result?.data?.result)
  //   }
  // }
  const userObj = JSON.parse(localStorage.getItem('user'))
  const fetchdata = async () => {
    const result = await apiget(`lead/list`);
  
    if (result && result.status === 200) {
      const filteredLeads = result?.data?.result.filter((lead) => lead.coordinatorName === userObj.Name);
      setLeadData(filteredLeads);
    }
  };

  const handleSelectionChange = (selectionModel) => {
    setSelectedRowIds(selectionModel);
  };


  useEffect(() => {
    fetchdata();
  }, [openAdd, userAction])
  return (
    <>
      {/* Add Lead Model */}
      <AddLead open={openAdd} handleClose={handleCloseAdd} setUserAction={setUserAction} />


      <Container>
        <Stack direction="row" alignItems="center" mb={5} justifyContent={"space-between"}>
          <Typography variant="h4" >
            Lead
          </Typography>
          <Stack direction="row" alignItems="center" justifyContent={"flex-end"} spacing={2}>
            <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleOpenAdd}>
              New Lead
            </Button>
          </Stack>
        </Stack>
        <TableStyle>
          <Box width="100%">
            <Card style={{ height: "600px", paddingTop: "15px" }}>
              <DataGrid
                rows={leadData}
                columns={columns}
                components={{ Toolbar: () => CustomToolbar({ selectedRowIds, fetchdata }) }}
                checkboxSelection
                onRowSelectionModelChange={handleSelectionChange}
                rowSelectionModel={selectedRowIds}
                getRowId={row => row._id}
              />
            </Card>
          </Box>
        </TableStyle>
      </Container >
    </>
  );
}

export default Lead