/* eslint-disable react/prop-types */
import * as React from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, InputAdornment, MenuItem, OutlinedInput, Radio, RadioGroup, Rating, Select, TextField } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import Autocomplete from '@mui/material/Autocomplete';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import ClearIcon from "@mui/icons-material/Clear";
import { useFormik } from 'formik';
import * as yup from "yup";
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { apiget, apipost } from '../../service/api';
import Palette from '../../theme/palette';



const Add = (props) => {

  const { open, handleClose, _id, setUserAction } = props
  const [user, setUser] = useState([])

  const userid = localStorage.getItem('user_id');
  const userdata = JSON.parse(localStorage.getItem('user'));

  // -----------  validationSchema
  const validationSchema = yup.object({
    clientName: yup.string().required("Name is required"),
    // emailAddress: yup.string().required("Email address is required"),
    phoneNumber: yup.string().required("Phone Number is required"),
    regDate: yup.string().required("Registration date is required"),
    leadStatus: yup.string().required("Lead status is required"),
    
  });


  

  // -----------   initialValues
  const initialValues = {
    clientName: "",
    emailAddress: "",
    phoneNumber: "",
    regDate: "",
    leadStatus: "",
    clientId: "",
    clientGenerationDate: "",
    firstTradeDate: "",
    fullTradeDate: "",
    fundAdd: "",
    fundAddDate: "",
    fundAddedBy: "",
    createdBy: userid
  };

  function getDaysBetweenDates(startDate, endDate) {
    const firstTradeDate = new Date(startDate);
    const fullTradeDate = new Date(startDate);
    firstTradeDate.setHours(0, 0, 0, 0);
    const clientGenerationDate = new Date(endDate);
    clientGenerationDate.setHours(0, 0, 0, 0);
  
    const startDateInMilliseconds = fullTradeDate.getTime();
    const endDateInMilliseconds = clientGenerationDate.getTime();
    const differenceInMilliseconds = endDateInMilliseconds - startDateInMilliseconds;
  
    // Round the days between to the nearest whole number
    const daysBetween = Math.round(differenceInMilliseconds / (1000 * 60 * 60 * 24));
  
    return daysBetween;
  }
  
  // add Lead api
  const addLead = async (values) => {
    const data = values;
    data.daysForFirstTrade=getDaysBetweenDates(data?.clientGenerationDate,data?.firstTradeDate);
    data.daysForFullTrade=getDaysBetweenDates(data?.clientGenerationDate,data?.fullTradeDate);
    

    const result = await apipost('lead/add', data)
    setUserAction(result)

    if (result && result.status === 201) {
      formik.resetForm();
      handleClose();
      toast.success(result.data.message)
    }
  }

  // formik
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      addLead(values)
    },
  });
  // user api
  const fetchUserData = async () => {
    const result = await apiget('user/list')
    if (result && result.status === 200) {
      setUser(result?.data?.result)
    }
  }

  useEffect(() => {
    fetchUserData();
  }, [])

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      // TransitionComponent={Transition}
      >
        <DialogTitle
          id="scroll-dialog-title"
          style={{
            display: "flex",
            justifyContent: "space-between",
            // backgroundColor: "#2b4054",
            // color: "white",
          }}
        >
          <Typography variant="h6">Add New</Typography>
          <Typography>
            <ClearIcon
              onClick={handleClose}
              style={{ cursor: "pointer" }}
            />
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <form>
            <DialogContentText
              id="scroll-dialog-description"
              tabIndex={-1}
            >
              <Typography style={{ marginBottom: "15px" }} variant="h6">
                Basic Information
              </Typography>
              <Grid
                container
                rowSpacing={3}
                columnSpacing={{ xs: 0, sm: 5, md: 4 }}
              >
               
                <Grid item xs={12} sm={12} md={12}>
                  <FormLabel>Name</FormLabel>
                  <TextField
                    id="clientName"
                    name="clientName"
                    label=""
                    size='small'
                    maxRows={10}
                    fullWidth
                    value={formik.values.clientName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.clientName &&
                      Boolean(formik.errors.clientName)
                    }
                    helperText={
                      formik.touched.clientName && formik.errors.clientName
                    }
                  />
                </Grid>
                
                
                <Grid item xs={12} sm={12} md={12}>
                {/* <Grid item xs={12} sm={4} md={4}> */}
                  <FormLabel>Phone number</FormLabel>
                  <TextField
                    id="phoneNumber"
                    name="phoneNumber"
                    type='number'
                    size='small'
                    fullWidth
                    value={formik.values.phoneNumber}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.phoneNumber &&
                      Boolean(formik.errors.phoneNumber)
                    }
                    helperText={
                      formik.touched.phoneNumber && formik.errors.phoneNumber
                    }
                  />
                </Grid>
                

                <Grid item xs={12} sm={12} md={12}>
                {/* <Grid item xs={12} sm={4} md={4}> */}
                  <FormLabel>Whatsapp number</FormLabel>
                  <TextField
                    id="whatsappNumber"
                    name="whatsappNumber"
                    type='number'
                    size='small'
                    fullWidth
                    value={formik.values.whatsappNumber}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.whatsappNumber &&
                      Boolean(formik.errors.whatsappNumber)
                    }
                    helperText={
                      formik.touched.whatsappNumber && formik.errors.whatsappNumber
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormLabel>Email</FormLabel>
                  <TextField
                    id="emailAddress"
                    name="emailAddress"
                    label=""
                    size='small'
                    fullWidth
                    value={formik.values.emailAddress}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.emailAddress &&
                      Boolean(formik.errors.emailAddress)
                    }
                    helperText={
                      formik.touched.emailAddress && formik.errors.emailAddress
                    }
                  />
                </Grid>
                
              </Grid>
             
              
              <Typography
                style={{ marginBottom: "15px", marginTop: "15px" }}
                variant="h6"
              >
                Lead Details
              </Typography>
              
              <Grid item xs={12} sm={6} md={6}>
                  <FormLabel>Registration Date</FormLabel>
                  <TextField
                    id="regDate"
                    name="regDate"
                    type='date'
                    size='small'
                    fullWidth
                    value={formik.values.regDate}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.regDate &&
                      Boolean(formik.errors.regDate)
                    }
                    helperText={
                      formik.touched.regDate && formik.errors.regDate
                    }
                  />
                </Grid>

                <Typography
                style={{ marginBottom: "15px", marginTop: "15px" }}
                variant="h6"
              >
                Account Details
              </Typography>
                <Grid item xs={12} sm={6} md={6}>
                  <FormLabel>Account Open Date</FormLabel>
                  <TextField
                    id="AccountOpenDate"
                    name="AccountOpenDate"
                    type='date'
                    size='small'
                    fullWidth
                    value={formik.values.AccountOpenDate}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.AccountOpenDate &&
                      Boolean(formik.errors.AccountOpenDate)
                    }
                    helperText={
                      formik.touched.AccountOpenDate && formik.errors.AccountOpenDate
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={4} md={4}>
  <FormControl fullWidth>
    <FormLabel>Coordinator Name</FormLabel>
    <Select
      labelId="demo-simple-select-label"
      id="coordinatorName"
      name="coordinatorName"
      label=""
      size="small"
      fullWidth
      value={formik.values.coordinatorName}
      onChange={formik.handleChange}
      error={formik.touched.coordinatorName && Boolean(formik.errors.coordinatorName)}
      helperText={formik.touched.coordinatorName && formik.errors.coordinatorName}
    >
      <Autocomplete
        freeSolo
        options={user.map((user) => user.Name)}
        renderInput={(params) => (
          <TextField {...params} label="Search Coordinator" variant="outlined" />
        )}
      />
      {/* Only render filtered options if a search term is present */}
      {formik.values.coordinatorName && (
        user
          .filter((user) => user.Name.toLowerCase().includes(formik.values.coordinatorName.toLowerCase()))
          .map((user) => (
            <MenuItem key={user._id} value={user.Name}>
              {user.Name}
            </MenuItem>
          ))
      )}
    </Select>
    <FormHelperText style={{ color: Palette.error.main }}>
      {formik.touched.coordinatorName && formik.errors.coordinatorName}
    </FormHelperText>
  </FormControl>
</Grid>



                <Grid item xs={12} sm={12} md={12}>
                  <FormControl fullWidth>
                    <FormLabel>Lead Status</FormLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="leadStatus"
                      name="leadStatus"
                      label=""
                      size='small'
                      fullWidth
                      value={formik.values.leadStatus}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.leadStatus &&
                        Boolean(formik.errors.leadStatus)
                      }
                      helperText={
                        formik.touched.leadStatus && formik.errors.leadStatus
                      }

                    >
                      <MenuItem value="Leads Under Review">Leads Under Review</MenuItem>
                      <MenuItem value="Ready For Trade">Ready For Trade </MenuItem>
                      <MenuItem value="1st Trade">1st Trade </MenuItem>
                      <MenuItem value="2nd Trade"> 2nd Trade </MenuItem>
                      <MenuItem value="Rejected" style={{ color: 'red' }}> Rejected </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>  

                <Typography style={{ marginBottom: "15px",  marginTop: "15px" }  } variant="h6">
                Client  ID Details
              </Typography>
                <Grid item xs={12} sm={12} md={12}>
                  <FormLabel>Client ID</FormLabel>
                  <TextField
                    id="clientId"
                    name="clientId"
                    label=""
                    size='small'
                    maxRows={10}
                    fullWidth
                    value={formik.values.clientId}
                    onChange={formik.handleChange}
                    
                  />
                </Grid> 
                <Grid item xs={12} sm={12} md={12}>
                  <FormLabel>Client Generation  Date</FormLabel>
                  <TextField
                    id="clientGenerationDate"
                    name="clientGenerationDate"
                    type='date'
                    size='small'
                    fullWidth
                
                    value={formik.values.clientGenerationDate}
                    onChange={formik.handleChange}
                    
                  />
                </Grid>

                <Typography
                style={{ marginBottom: "15px", marginTop: "15px" }}
                variant="h6"
              >
                Application Details
              </Typography>

              <Grid item xs={12} sm={12} md={12}>
                  <FormLabel>Application Number</FormLabel>
                  <TextField
                    id="ApplicationNumber"
                    name="ApplicationNumber"
                    label=""
                    size='small'
                    maxRows={10}
                    fullWidth
                    value={formik.values.ApplicationNumber}
                    onChange={formik.handleChange}
                    
                  />
                </Grid> 
                <Typography
                style={{ marginBottom: "15px", marginTop: "15px" }}
                variant="h6"
              >
                Leaders
              </Typography>

              <Grid item xs={12} sm={12} md={12}>
                  <FormLabel>Team Leader Name</FormLabel>
                  <TextField
                    id="teamLeaderName"
                    name="teamLeaderName"
                    label=""
                    size='small'
                    maxRows={10}
                    fullWidth
                    value={formik.values.teamLeaderName}
                    onChange={formik.handleChange}
                    
                  />
                </Grid> 


                <Typography style={{ marginBottom: "15px" ,  marginTop: "15px"}} variant="h6">
                Trade Details
              </Typography>
                <Grid item xs={12} sm={12} md={12}>
                  <FormLabel>First Trade  Date</FormLabel>
                  <TextField
                    id="firstTradeDate"
                    name="firstTradeDate"
                    type='date'
                    size='small'
                    fullWidth
                    value={formik.values.firstTradeDate}
                    onChange={formik.handleChange}
                    
                  />
                </Grid>
                
                <Grid item xs={12} sm={4} md={4}>
                  <FormControl fullWidth>
                    <FormLabel>First Trade By</FormLabel>
                    <Select
                    labelId="demo-simple-select-label"
                    id="firstTradeBy"
                    name="firstTradeBy"
                    label=""
                    size='small'
                    fullWidth
                    value={formik.values.firstTradeBy}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.firstTradeBy &&
                      Boolean(formik.errors.firstTradeBy)
                    }
                    helperText={
                      formik.touched.firstTradeBy && formik.errors.firstTradeBy
                    }
                  >
                    <Autocomplete
        freeSolo
        options={user.map((user) => user.Name)}
        renderInput={(params) => (
          <TextField {...params} label="Search Coordinator" variant="outlined" />
        )}
      />
      {/* Only render filtered options if a search term is present */}
      {formik.values.firstTradeBy && (
        user
          .filter((user) => user.Name.toLowerCase().includes(formik.values.firstTradeBy.toLowerCase()))
          .map((user) => (
            <MenuItem key={user._id} value={user.Name}>
              {user.Name}
            </MenuItem>
          ))
      )}
                  </Select>
                    <FormHelperText style={{ color: Palette.error.main }}>{formik.touched.firstTradeBy && formik.errors.firstTradeBy}</FormHelperText>
                  </FormControl>
                </Grid>
                
                <Grid item xs={12} sm={12} md={12}>
                  <FormLabel>Full Trade Date</FormLabel>
                  <TextField
                    id="fullTradeDate"
                    name="fullTradeDate"
                    type='date'
                    size='small'
                    fullWidth
                    value={formik.values.fullTradeDate}
                    onChange={formik.handleChange}
                    
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <FormControl fullWidth>
                    <FormLabel>Full Trade By</FormLabel>
                    <Select
                    labelId="demo-simple-select-label"
                    id="fullTradeBy"
                    name="fullTradeBy"
                    label=""
                    size='small'
                    fullWidth
                    value={formik.values.fullTradeBy}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.fullTradeBy &&
                      Boolean(formik.errors.firstTradeBy)
                    }
                    helperText={
                      formik.touched.fullTradeBy && formik.errors.fullTradeBy
                    }
                  >
                   <Autocomplete
        freeSolo
        options={user.map((user) => user.Name)}
        renderInput={(params) => (
          <TextField {...params} label="Search Coordinator" variant="outlined" />
        )}
      />
      {/* Only render filtered options if a search term is present */}
      {formik.values.fullTradeBy && (
        user
          .filter((user) => user.Name.toLowerCase().includes(formik.values.fullTradeBy.toLowerCase()))
          .map((user) => (
            <MenuItem key={user._id} value={user.Name}>
              {user.Name}
            </MenuItem>
          ))
      )}
                  </Select>
                    <FormHelperText style={{ color: Palette.error.main }}>{formik.touched.fullTradeBy && formik.errors.fullTradeBy}</FormHelperText>
                  </FormControl>
                </Grid>

                <Typography style={{ marginBottom: "15px" ,  marginTop: "15px"}} variant="h6">
                Fund Details
              </Typography>
                <Grid item xs={12} sm={12} md={12}>
                  <FormLabel>Fund Add</FormLabel>
                  <TextField
                    id="fundAdd"
                    name="fundAdd"
                    label=""
                    size='small'
                    maxRows={10}
                    fullWidth
                    value={formik.values.fundAdd}
                    onChange={formik.handleChange}
                    
                  />
                </Grid> 
                <Grid item xs={12} sm={12} md={12}>
                  <FormLabel>Fund Add  Date</FormLabel>
                  <TextField
                    id="fundAddDate"
                    name="fundAddDate"
                    type='date'
                    size='small'
                    fullWidth
                    value={formik.values.fundAddDate}
                    onChange={formik.handleChange}
                    
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <FormControl fullWidth>
                    <FormLabel>Fund Added By</FormLabel>
                    <Select
                    labelId="demo-simple-select-label"
                    id="fundAddedBy"
                    name="fundAddedBy"
                    label=""
                    size='small'
                    fullWidth
                    value={formik.values.fundAddedBy}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.fundAddedBy &&
                      Boolean(formik.errors.fundAddedBy)
                    }
                    helperText={
                      formik.touched.fundAddedBy && formik.errors.fundAddedBy
                    }
                  >
                   <Autocomplete
        freeSolo
        options={user.map((user) => user.Name)}
        renderInput={(params) => (
          <TextField {...params} label="Search Coordinator" variant="outlined" />
        )}
      />
      {/* Only render filtered options if a search term is present */}
      {formik.values.fundAddedBy && (
        user
          .filter((user) => user.Name.toLowerCase().includes(formik.values.fundAddedBy.toLowerCase()))
          .map((user) => (
            <MenuItem key={user._id} value={user.Name}>
              {user.Name}
            </MenuItem>
          ))
      )}
                  </Select>
                    <FormHelperText style={{ color: Palette.error.main }}>{formik.touched.fundAddedBy && formik.errors.fundAddedBy}</FormHelperText>
                  </FormControl>
                </Grid>
                <Typography style={{ marginBottom: "15px" ,  marginTop: "15px"}} variant="h6">
                Brokerage
              </Typography>
                <Grid item xs={12} sm={12} md={12}>
                  <FormLabel>Brokerage</FormLabel>
                  <TextField
                    id="Brokerage"
                    name="BrokerAge"
                    label=""
                    size='small'
                    maxRows={10}
                    fullWidth
                    value={formik.values.Brokerage}
                    onChange={formik.handleChange}
                    
                  />
                </Grid> 
                
            </DialogContentText>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={formik.handleSubmit} variant='contained' color='primary'>Save</Button>
          <Button onClick={() => {
            formik.resetForm()
            handleClose()
          }} variant='outlined' color='error'>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Add