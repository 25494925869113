/* eslint-disable react/prop-types */
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, InputAdornment, MenuItem, OutlinedInput, Radio, RadioGroup, Rating, Select, TextField } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import ClearIcon from "@mui/icons-material/Clear";
import { useFormik } from 'formik';
import * as yup from "yup";
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { apiget, apipost } from '../../service/api';
import Palette from '../../theme/palette';

const Add = (props) => {

  const { open, handleClose, _id, setUserAction } = props
  const [user, setUser] = useState([])

  const userid = localStorage.getItem('user_id');
  const userdata = JSON.parse(localStorage.getItem('user'));

  // -----------  validationSchema
  const validationSchema = yup.object({
    clientName: yup.string().required("Name is required"),
    phoneNumber: yup.string().required("Phone Number is required"),
    regDate: yup.string().required("Registration date is required"),
    
    
  });

  

  

  // -----------   initialValues
  const userObj = JSON.parse(localStorage.getItem('user'))
  const initialValues = {
    clientName: "",
    emailAddress: "",
    phoneNumber: "",
    regDate: "",
    leadStatus: "",
    clientId: "",
    clientGenerationDate: "",
    firstTradeDate: "",
    fullTradeDate: "",
    fundAdd: "",
    fundAddDate: "",
    fundAddedBy: "",
    createdBy: userid,
    coordinatorName: userObj.Name
  };

  // add Lead api
  const addLead = async (values) => {
    const data = values;
    console.log(data);
    const result = await apipost('lead/add', data)
    setUserAction(result)

    if (result && result.status === 201) {
      formik.resetForm();
      handleClose();
      toast.success(result.data.message)
    }
  }

  // formik
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      addLead(values)
    },
  });
  // user api
  const fetchUserData = async () => {
    const result = await apiget('user/list')
    if (result && result.status === 200) {
      setUser(result?.data?.result)
    }
  }

  useEffect(() => {
    fetchUserData();
  }, [])

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      // TransitionComponent={Transition}
      >
        <DialogTitle
          id="scroll-dialog-title"
          style={{
            display: "flex",
            justifyContent: "space-between",
            // backgroundColor: "#2b4054",
            // color: "white",
          }}
        >
          <Typography variant="h6">Add New</Typography>
          <Typography>
            <ClearIcon
              onClick={handleClose}
              style={{ cursor: "pointer" }}
            />
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <form>
            <DialogContentText
              id="scroll-dialog-description"
              tabIndex={-1}
            >
              <Typography style={{ marginBottom: "15px" }} variant="h6">
                Basic Information
              </Typography>
              
               
                <Grid item xs={12} sm={12} md={12}>
                  <FormLabel>Name</FormLabel>
                  <TextField
                    id="clientName"
                    name="clientName"
                    label=""
                    size='small'
                    maxRows={10}
                    fullWidth
                    value={formik.values.clientName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.clientName &&
                      Boolean(formik.errors.clientName)
                    }
                    helperText={
                      formik.touched.clientName && formik.errors.clientName
                    }
                  />
                </Grid>
                
                
                <Grid item xs={12} sm={12} md={12}>
                  <FormLabel>Phone number</FormLabel>
                  <TextField
                    id="phoneNumber"
                    name="phoneNumber"
                    type='number'
                    size='small'
                    fullWidth
                    value={formik.values.phoneNumber}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.phoneNumber &&
                      Boolean(formik.errors.phoneNumber)
                    }
                    helperText={
                      formik.touched.phoneNumber && formik.errors.phoneNumber
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormLabel>Email</FormLabel>
                  <TextField
                    id="emailAddress"
                    name="emailAddress"
                    label=""
                    size='small'
                    fullWidth
                    value={formik.values.emailAddress}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.emailAddress &&
                      Boolean(formik.errors.emailAddress)
                    }
                    helperText={
                      formik.touched.emailAddress && formik.errors.emailAddress
                    }
                  />
                </Grid>
                
              
             
              
              <Typography
                style={{ marginBottom: "15px", marginTop: "15px" }}
                variant="h6"
              >
                Lead Details
              </Typography>
              <Grid item xs={12} sm={6} md={6}>
                  <FormLabel>Registration Date</FormLabel>
                  <TextField
                    id="regDate"
                    name="regDate"
                    type='date'
                    size='small'
                    fullWidth
                    value={formik.values.regDate}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.regDate &&
                      Boolean(formik.errors.regDate)
                    }
                    helperText={
                      formik.touched.regDate && formik.errors.regDate
                    }
                  />
                </Grid>
                {/* <Grid item xs={12} sm={12} md={12}>
                  <FormControl fullWidth>
                    <FormLabel>Lead Status</FormLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="leadStatus"
                      name="leadStatus"
                      label=""
                      size='small'
                      fullWidth
                      value={formik.values.leadStatus}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.leadStatus &&
                        Boolean(formik.errors.leadStatus)
                      }
                      helperText={
                        formik.touched.leadStatus && formik.errors.leadStatus
                      }

                    >
                      <MenuItem value="Leads Under Review">Leads Under Review</MenuItem>
                      <MenuItem value="Ready For Trade">Ready For Trade </MenuItem>
                      <MenuItem value="1st Trade">1st Trade </MenuItem>
                      <MenuItem value="2nd Trade"> 2nd Trade </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>   */}

                <Typography style={{ marginBottom: "15px",  marginTop: "15px" }  } variant="h6">
                Client  ID Details
              </Typography>
                <Grid item xs={12} sm={12} md={12}>
                  <FormLabel>Client ID</FormLabel>
                  <TextField
                    id="clientId"
                    name="clientId"
                    label=""
                    size='small'
                    maxRows={10}
                    fullWidth
                    value={formik.values.clientId}
                    onChange={formik.handleChange}
                    
                  />
                </Grid> 
                <Grid item xs={12} sm={12} md={12}>
                  <FormLabel>Client Generation  Date</FormLabel>
                  <TextField
                    id="clientGenerationDate"
                    name="clientGenerationDate"
                    type='date'
                    size='small'
                    fullWidth
                    value={formik.values.clientGenerationDate}
                    onChange={formik.handleChange}
                    
                  />
                </Grid>
                
                {/* <Grid item xs={12} sm={4} md={4}>
                  <FormControl fullWidth>
                    <FormLabel>Coordinator Name</FormLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="coordinatorName"
                      name="coordinatorName"
                      label=""
                      size='small'
                      fullWidth
                      value={formik.values.coordinatorName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.coordinatorName &&
                        Boolean(formik.errors.coordinatorName)
                      }
                      helperText={
                        formik.touched.coordinatorName && formik.errors.coordinatorName
                      }
                    >
                      {
                        user.role === 'user' ?
                          user.map((user) => {
                            if (user.role === 'user') {
                              return (
                                <MenuItem key={user._id} value={user.Name}>
                                  {`${user.Name} ${user.Name}`}
                                </MenuItem>
                              );
                            }
                            return null;
                          })
                          :
                          <MenuItem key={userdata._id} value={userdata._id}>
                            {`${userdata.firstName} ${userdata.lastName}`}
                          </MenuItem>
                      }
                    </Select>
                    <FormHelperText style={{ color: Palette.error.main }}>{formik.touched.coordinatorName && formik.errors.coordinatorName}</FormHelperText>
                  </FormControl>
                </Grid> */}
                <Grid item xs={12} sm={6} md={6}>
                  <FormLabel>Account Open Date</FormLabel>
                  <TextField
                    id="AccountOpenDate"
                    name="AccountOpenDate"
                    type='date'
                    size='small'
                    fullWidth
                    value={formik.values.AccountOpenDate}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.AccountOpenDate &&
                      Boolean(formik.errors.AccountOpenDate)
                    }
                    helperText={
                      formik.touched.AccountOpenDate && formik.errors.AccountOpenDate
                    }
                  />
                </Grid>
            </DialogContentText>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={formik.handleSubmit} variant='contained' color='primary'>Save</Button>
          <Button onClick={() => {
            formik.resetForm()
            handleClose()
          }} variant='outlined' color='error'>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Add