/* eslint-disable react/prop-types */
import { Box, Card, Grid, Typography } from '@mui/material'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import Palette from '../../theme/palette'
import Addemail from '../../components/email/Addemail'

const Overview = ({ data, setUserAction }) => {
  const [open, setOpen] = useState(false)
console.log(data)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  // function getDaysBetweenDates(startDate, endDate) {
  
  function getDaysBetweenDates(startDate, endDate) {
    const firstTradeDate = new Date(startDate);
    const fullTradeDate = new Date(startDate);
    firstTradeDate.setHours(0, 0, 0, 0);
    const clientGenerationDate = new Date(endDate);
    clientGenerationDate.setHours(0, 0, 0, 0);
  
    const startDateInMilliseconds = fullTradeDate.getTime();
    const endDateInMilliseconds = clientGenerationDate.getTime();
    const differenceInMilliseconds = endDateInMilliseconds - startDateInMilliseconds;
  
    // Round the days between to the nearest whole number
    const daysBetween = Math.round(differenceInMilliseconds / (1000 * 60 * 60 * 24));
  
    return daysBetween;
  }
  
  return (
    <div>
      {/* Add Email Model */}
      <Addemail open={open} handleClose={handleClose} _id={data?._id} receiver={data} setUserAction={setUserAction} />

      <Card style={{borderTopLeftRadius:"0px",borderTopRightRadius:"0px"}}>
        <Box p={3}>
          <Grid container display="flex" spacing={4}>
            <Grid item xs={12} sm={6}>
              <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} pb={2}>
                <Typography variant="body1">Name :</Typography>
                <Typography variant="body2" color={Palette.grey[600]} textTransform={"capitalize"}>{` ${data?.clientName}`}</Typography>
              </Grid>
              
              <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400], }} py={2}>
                <Typography variant="body1">Registration Date :</Typography>
                <Typography variant="body2" color={Palette.grey[600]}>
                  {
                    data?.regDate ? dayjs(data?.regDate).format("DD/MM/YYYY") : "null"
                  }
                </Typography>
              </Grid>
              <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                <Typography variant="body1">Client ID :</Typography>
                <Typography variant="body2" color={Palette.grey[600]}>{data?.clientId ? data?.clientId : "null"}</Typography>
              </Grid>
              
              
              
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} pb={2}>
                <Typography variant="body1">Phone Number :</Typography>
                <Typography variant="body2" color={Palette.grey[600]}>{data?.phoneNumber ? data?.phoneNumber : "null"}</Typography>
              </Grid>
              <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                <Typography variant="body1">Email :</Typography>
                <Typography variant="body2" color={Palette.primary.main} onClick={handleOpen} style={{ cursor: "pointer" }}>{data?.emailAddress ? data?.emailAddress : "null"}</Typography>
              </Grid>
              <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                <Typography variant="body1">Client Generation Date :</Typography>
                <Typography variant="body2" color={Palette.grey[600]}>{data?.clientGenerationDate ? data?.clientGenerationDate : "null"}</Typography>
              </Grid>
              
              <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                <Typography variant="body1">Client ID :</Typography>
                <Typography variant="body2" color={Palette.grey[600]}>{data?.clientId ? data?.ClientId : "null"}</Typography>
              </Grid>
              
              
             
            </Grid>
          </Grid>
        </Box>
      </Card>
    </div>
  )
}

export default Overview
