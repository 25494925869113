/* eslint-disable react-hooks/exhaustive-deps */
import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
// components
import { useEffect, useState } from 'react';
import Iconify from '../components/iconify';

// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
import { apiget } from '../service/api';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const theme = useTheme();

  const [totalLead, setTotalLead] = useState([])
  const [totalSuperdone, setTotalSuperdone] = useState([])
  const [totalReview, setTotalReview] = useState([])
  const [totafirstTrade, setfirstTrade] = useState([])
  const [totalfullTrade, settotalfullTrade] = useState([])
  const [totalfirstTradePercentage, setFirstTradePercentage] = useState([])
  const [totalfullTradePercentage, setFullTradePercentage] = useState([])
  const [totalunderReviewPercentage, setunderReviewPercentage] = useState([])
  const [totalSuperDonePercentage, setSuperDonePercentage] = useState([])
  const [totalApplications,  setApplicationpercentage] = useState([])
  const [totalUsercreated, settotalUsercreated] = useState([])
  const userid = localStorage.getItem('user_id');
  const userRole = localStorage.getItem("userRole")

  
  // lead api
  const fetchLead = async () => {
    const result = await apiget(userRole === 'admin' ? `lead/list` : `lead/list/?createdBy=${userid}`)
    if (result && result.status === 200) {
      
      
      setTotalLead(result?.data?.total_recodes)
    }
  }
  
  //  // lead under review api
   const fetchreview = async () => {
    const result = await apiget(userRole === 'admin' ? `lead/list` : `lead/list/?createdBy=${userid}`)
    if (result && result.status === 200) {
      const filteredData = result?.data?.result.filter((row) => row.leadStatus === 'Leads Under Review');
      
      setTotalReview(filteredData.length)
      
    }
   
  }
  // under review percentage
  const fetchUnderReviewcentage = async () => {
    const result = await apiget(userRole === 'admin' ? `lead/list` : `lead/list/?createdBy=${userid}`)
    if (result && result.status === 200) {
      const totalLeads = result?.data?.result.length;
      const underReviewCount = result?.data?.result.filter((row) => row.leadStatus === 'Leads Under Review').length;
  
      const underReviewPercentage = (underReviewCount / totalLeads) * 100;
      const formattedPercentage = underReviewPercentage.toFixed(2); // Round to two decimal places
  
      setunderReviewPercentage(formattedPercentage);
    }
  };

   //  // lead superdone
   const fetchSuperdone = async () => {
    const result = await apiget(userRole === 'admin' ? `lead/list` : `lead/list/?createdBy=${userid}`)
    if (result && result.status === 200) {
      const filteredData = result?.data?.result.filter((row) => row.leadStatus === 'Ready For Trade');
      
      setTotalSuperdone(filteredData.length)
      
    }
  }

  // superdone  percentage
  const fetchSuperDonepercentage = async () => {
    const result = await apiget(userRole === 'admin' ? `lead/list` : `lead/list/?createdBy=${userid}`)
    if (result && result.status === 200) {
      const totalLeads = result?.data?.result.length;
      const SuperDoneCount = result?.data?.result.filter((row) => row.leadStatus === 'Ready For Trade').length;
  
      const SuperDonePercentage = (SuperDoneCount / totalLeads) * 100;
      const formattedPercentage = SuperDonePercentage.toFixed(2); // Round to two decimal places
  
      setSuperDonePercentage(formattedPercentage);
    }
  };
    // lead first trade
   const fetchFirstTrade = async () => {
    const result = await apiget(userRole === 'admin' ? `lead/list` : `lead/list/?createdBy=${userid}`)
    if (result && result.status === 200) {
      const filteredData = result?.data?.result.filter((row) => row.leadStatus === '1st Trade');
      
      setfirstTrade(filteredData.length)
      
    }
  }
  // first trade percentage
  const fetchFirstTradepercentage = async () => {
    const result = await apiget(userRole === 'admin' ? `lead/list` : `lead/list/?createdBy=${userid}`)
    if (result && result.status === 200) {
      const totalLeads = result?.data?.result.length;
      const firstTradeCount = result?.data?.result.filter((row) => row.leadStatus === '1st Trade').length;
  
      const firstTradePercentage = (firstTradeCount / totalLeads) * 100;
      const formattedPercentage = firstTradePercentage.toFixed(2); // Round to two decimal places
  
      setFirstTradePercentage(formattedPercentage);
    }
  };
  //  // lead full trade
  const fetchfullTrade = async () => {
    const result = await apiget(userRole === 'admin' ? `lead/list` : `lead/list/?createdBy=${userid}`)
    if (result && result.status === 200) {
      const filteredData = result?.data?.result.filter((row) => row.leadStatus === '2nd Trade');
      
      settotalfullTrade(filteredData.length)
      
    }
  }
  // full trade percentage
  const fetchFullTradepercentage = async () => {
    const result = await apiget(userRole === 'admin' ? `lead/list` : `lead/list/?createdBy=${userid}`)
    if (result && result.status === 200) {
      const totalLeads = result?.data?.result.length;
      const fullTradeCount = result?.data?.result.filter((row) => row.leadStatus === '2nd Trade').length;
  
      const fullTradePercentage = (fullTradeCount / totalLeads) * 100;
      const formattedPercentage = fullTradePercentage.toFixed(2); // Round to two decimal places
  
      setFullTradePercentage(formattedPercentage);
    }
  };
   //  // lead User Created
   const fetchUsercreated = async () => {
    const result = await apiget(userRole === 'admin' ? `lead/list` : `lead/list/?createdBy=${userid}`)
    if (result && result.status === 200) {
      const filteredData = result?.data?.result.filter((row) => row.leadStatus === '');
      
      settotalUsercreated(filteredData.length)
      
    }
  }

// Percentage api

  

  // app percentage
const fetchApplicationpercentage = async () => {
  const result = await apiget(userRole === 'admin' ? `lead/list` : `lead/list/?createdBy=${userid}`)
  if (result && result.status === 200) {
    const totalLeads = result?.data?.result.length;
     const ApplicationCount = result?.data?.result.filter((row) => row.ApplicationNumber && row.ApplicationNumber.trim().length > 0);


    const Applicationpercentage = (ApplicationCount / totalLeads) * 100;
    const formattedPercentage = Applicationpercentage.toFixed(2); // Round to two decimal places

    setApplicationpercentage(ApplicationCount.length);
  }
};
  

  

 

  useEffect(() => {
    fetchLead();
    fetchSuperdone();
    fetchFirstTrade();
    fetchFirstTradepercentage();
    fetchFullTradepercentage();
    fetchApplicationpercentage();
    fetchSuperDonepercentage();
    fetchUnderReviewcentage();

    fetchreview();
    fetchfullTrade();
    fetchUsercreated();
  }, [])
  return (
    <>
      <Helmet>
        {/* <title> Dashboard | Minimal UI </title> */}
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Equity Pathshala Welcome back 
        </Typography>


        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Total Leads" total={totalLead} icon={'ic:baseline-leaderboard'} />
          </Grid>

         
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Leads Under Review" total={`${totalunderReviewPercentage}%`} color="info" icon={'fluent-mdl2:review-request-solid'} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Leads Superdone" total={`${totalSuperDonePercentage}%`} color="info" icon={':icon-park:done-all'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="1st Trade Done" total={`${totalfirstTradePercentage}%`} color="info" icon={'iconoir:medal-1st-solid'} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="full Trade Done" total={`${totalfullTradePercentage}%`} color="info" icon={'emojione-monotone:2nd-place-medal'} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="New Leads Added by User" total={totalUsercreated} color="info" icon={'gridicons:add-outline'} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Applications" total={totalApplications} color="info" icon={'fa:mobile'} />
          </Grid>
          

         

           <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits
              title="Website Visits"
              subheader="(+43%) than last year"
              chartLabels={[
                '01/01/2003',
                '02/01/2003',
                '03/01/2003',
                '04/01/2003',
                '05/01/2003',
                '06/01/2003',
                '07/01/2003',
                '08/01/2003',
                '09/01/2003',
                '10/01/2003',
                '11/01/2003',
               ]}
              chartData={[
                {
                  name: 'Team A',
                  type: 'column',
                  fill: 'solid',
                  data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
                },
                {
                  name: 'Team B',
                  type: 'area',
                  fill: 'gradient',
                  data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
                },
                {
                  name: 'Team C',
                  type: 'line',
                  fill: 'solid',
                  data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
                },
              ]}
            />
          </Grid> 

          
          
          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title="Lead Status"
              chartData={[
                { label: 'Leads Under Review', value:totalReview },
                { label: 'Leads Superdone', value: totalSuperdone },
                { label: 'First Trade Completed', value: totafirstTrade },
                { label: 'Full Trade Completed', value: totalfullTrade },
                { label: 'leads Created by user(pending)', value: totalUsercreated },
              ]}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.info.main,
                theme.palette.warning.main,
                theme.palette.error.main,
              ]}
            />
          </Grid>



          

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate
              title="News Update"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: faker.name.jobTitle(),
                description: faker.name.jobTitle(),
                image: `/assets/images/covers/cover_${index + 1}.jpg`,
                postedAt: faker.date.recent(),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline
              title="Order Timeline"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: [
                  '1983, orders, $4220',
                  '12 Invoices have been paid',
                  'Order #37745 from September',
                  'New order placed #XF-2356',
                  'New order placed #XF-2346',
                ][index],
                type: `order${index + 1}`,
                time: faker.date.past(),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite
              title="Traffic by Site"
              list={[
                {
                  name: 'FaceBook',
                  value: 323234,
                  icon: <Iconify icon={'eva:facebook-fill'} color="#1877F2" width={32} />,
                },
                {
                  name: 'Google',
                  value: 341212,
                  icon: <Iconify icon={'eva:google-fill'} color="#DF3E30" width={32} />,
                },
                {
                  name: 'Linkedin',
                  value: 411213,
                  icon: <Iconify icon={'eva:linkedin-fill'} color="#006097" width={32} />,
                },
                {
                  name: 'Twitter',
                  value: 443232,
                  icon: <Iconify icon={'eva:twitter-fill'} color="#1C9CEA" width={32} />,
                },
              ]}
            />
          </Grid>
          

          <Grid item xs={12} md={6} lg={8}>
            <AppTasks
              title="Tasks"
              list={[
                { id: '1', label: 'Create FireStone Logo' },
                { id: '2', label: 'Add SCSS and JS files if required' },
                { id: '3', label: 'Stakeholder Meeting' },
                { id: '4', label: 'Scoping & Estimations' },
                { id: '5', label: 'Sprint Showcase' },
              ]}
            />
          </Grid> */}
        </Grid>
      </Container>
    </>
  );
}
