import React, { useState } from "react";
import axios from "axios";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";

const ImportData = (props) => {
  const { open, handleClose, _id, setUserAction } = props

  const [file, setFile] = useState(null);
  const [message, setMessage] = useState("");

  const handleChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      setMessage("Please select a CSV file to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      // const response = await axios.post("https://crm-backend-o3e3.onrender.com/importUser", formData);
      const response = await axios.post("http://localhost:5000/importUser", formData);
      setMessage(response.data.message);
      setFile(null);
      handleClose();
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };

  
  return (
    <div>
      
      
      <Dialog  open={open}
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      // TransitionComponent={Transition}
      >
        <DialogTitle>Import CSV File</DialogTitle>
        <DialogContent>
          <TextField
            type="file"
            accept=".csv"
            onChange={handleChange}
            fullWidth
          />
          <p>{message}</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUpload} variant="contained">
            Upload
          </Button>
          <Button onClick={handleClose} variant="outlined">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ImportData;
