import React, { useEffect, useState } from 'react';
import { Card, Stack, Button, Container, Typography, Box } from '@mui/material';
import { DataGrid, GridToolbar, GridToolbarContainer } from '@mui/x-data-grid';
import { DeleteOutline, Clear as ClearIcon } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import Iconify from '../../components/iconify';
import AddLead from './Add';
import { updateLeadStatusApi, apiget, deleteManyApi } from '../../service/api';
import DeleteModel from '../../components/Deletemodle';
import TableStyle from '../../components/TableStyle';
import EditModel from './Edit';

function CustomToolbar({ selectedRowIds, fetchdata, setUserActionProp, handleRejectLead }) {
  const [opendelete, setOpendelete] = useState(false);

  const handleCloseDelete = () => setOpendelete(false);
  const handleOpenDelete = () => setOpendelete(true);

  const deleteManyLead = async (data) => {
    const result = await deleteManyApi('lead/deletemany', data);
    fetchdata();
    setUserActionProp(result);
    handleCloseDelete();
  };

  useEffect(() => {
    // You can remove this useEffect if not needed
    // setUserActionProp(setUserActionProp);
  }, [setUserActionProp]);

  return (
    <GridToolbarContainer>
      <GridToolbar />
      {selectedRowIds && selectedRowIds.length > 0 && (
        <Button variant="text" sx={{ textTransform: 'capitalize' }} startIcon={<DeleteOutline />} onClick={handleOpenDelete}>
          Delete
        </Button>
      )}
      <DeleteModel opendelete={opendelete} handleClosedelete={handleCloseDelete} deletedata={deleteManyLead} id={selectedRowIds} />
    </GridToolbarContainer>
  );
}

const FullTrade = () => {
  const [userAction, setUserAction] = useState(null);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [leadData, setLeadData] = useState([]);
  const [id, setId] = useState('');
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const navigate = useNavigate();

  const userid = localStorage.getItem('user_id');
  const userRole = localStorage.getItem('userRole');

  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  const handleOpenAdd = () => setOpenAdd(true);
  const handleCloseAdd = () => setOpenAdd(false);


  
  const handleRejectLead = async (leadId) => {
    console.log('handleRejectLead function called');
    try {
      console.log('Lead ID before API call:', leadId);
      const result = await updateLeadStatusApi(leadId, 'Rejected');
      console.log('API Result:', result);
      setUserAction(result);
      console.log('userAction state after setting:', userAction);
      fetchdata();
    } catch (error) {
      console.error('Failed to reject lead:', error);
    }
  };
  
  

  const columns = [
    {
      field: 'clientName',
      headerName: 'Name',
      flex: 1,
      cellClassName: 'name-column--cell name-column--cell--capitalize',
      renderCell: (params) => {
        const handleFirstNameClick = () => {
          navigate(`/dashboard/lead/view/${params.row._id}`);
        };

        return <Box onClick={handleFirstNameClick}>{params.value}</Box>;
      },
    },
    {
      field: 'phoneNumber',
      headerName: 'Phone Number',
      flex: 1,
    },
    {
      field: 'emailAddress',
      headerName: 'Email Address',
      flex: 1,
    },
    {
      field: 'firstTradeDate',
      headerName: 'First Trade Date',
      flex: 1,
    },
    {
      field: 'fullTradeDate',
      headerName: 'Full Trade Date',
      flex: 1,
    },
    {
      field: 'leadStatus',
      headerName: 'Lead Status',
      flex: 1,
      renderCell: (params) => {
        const leadStatus = params.value;
        const cellStyle = leadStatus === '2nd Trade' ? { color: '#191970', fontWeight: 'bold' } : {};

        return <div style={cellStyle}>{leadStatus}</div>;
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      renderCell: (params) => {
        const handleFirstNameClick = async (data) => {
          setId(data);
          handleOpenEdit();
        };

        return (
          <>
            <EditModel open={openEdit} handleClose={handleCloseEdit} id={id} fetchLead={fetchdata} />
            <Button variant="text" size="small" color="primary" onClick={() => handleFirstNameClick(params?.row?._id)}>
              <EditIcon />
            {/* </Button> */}
            {/* <Button variant="text" size="small" color="primary" onClick={() => handleRejectLead(params?.row?._id)}> */}
              {/* <ClearIcon /> */}
            </Button>
          </>
        );
      },
    },
  ];

  const fetchdata = async () => {
    const result = await apiget(userRole === 'admin' ? `lead/list` : `lead/list/?createdBy=${userid}`);
    if (result && result.status === 200) {
      const filteredData = result?.data?.result.filter((row) => row.leadStatus === '2nd Trade');
      setLeadData(filteredData);
    }
  };

  const handleSelectionChange = (selectionModel) => {
    setSelectedRowIds(selectionModel);
  };

  useEffect(() => {
    fetchdata();
  }, [openAdd, userAction]);

  return (
    <>
      <AddLead open={openAdd} handleClose={handleCloseAdd} setUserAction={setUserAction} />

      <Container>
        <Stack direction="row" alignItems="center" mb={5} justifyContent={'space-between'}>
          <Typography variant="h4">Lead Full Trade</Typography>
          <Stack direction="row" alignItems="center" justifyContent={'flex-end'} spacing={2}>
            <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleOpenAdd}>
              New Lead
            </Button>
          </Stack>
        </Stack>
        <TableStyle>
          <Box width="100%">
            <Card style={{ height: '600px', paddingTop: '15px' }}>
              <DataGrid
                rows={leadData}
                columns={columns}
                components={{ Toolbar: () => CustomToolbar({ selectedRowIds, fetchdata, setUserActionProp: setUserAction, handleRejectLead }) }}
                checkboxSelection
                onRowSelectionModelChange={handleSelectionChange}
                rowSelectionModel={selectedRowIds}
                getRowId={(row) => row._id}
              />
            </Card>
          </Box>
        </TableStyle>
      </Container>
    </>
  );
};

export default FullTrade;
