import React, { useState, useEffect } from 'react';
import { TextField, Button, Grid } from '@mui/material';
import { AppWidgetSummary } from '../../sections/@dashboard/app';
import { apiget } from '../../service/api';

const UserProgressionForm = () => {
  const [Name, setfirstName] = React.useState('');
  const [fromDate, setFromDate] = React.useState('');
  const [toDate, setToDate] = React.useState('');

  const [totalProgression, setTotalProgression] = useState([]);
  const [totalFund,  setTotalFund] = useState([]);
 
  const [totalAccount, setTotalAccount] = useState([]);
  const [totalFullTrade, setTotalFullTrade] = useState([]);
  const [Nameuser, setNameuser] = useState(null);
  const userRole = localStorage.getItem('userRole');
  const [user, setUser] = useState([])

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('frontend');
    const response = await fetch('https://crm-backend-o3e3.onrender.com/user-progression/user-progression', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Name,
        fromDate,
        toDate,
      }),
    });
    // console.log("res:",response);

    const progressionData = await response.json();
    console.log(progressionData);
    setNameuser(progressionData.Name);
  };



// first trade api

  const fetchprogression = async () => {
    const result = await apiget(userRole === 'admin' ? `lead/list` : '');

    if (result && result.status === 200) {
      const filteredData = result?.data?.result.filter(
        (row) =>
          row.firstTradeBy === Nameuser &&
          new Date(row.createdOn) >= new Date(fromDate) &&
          new Date(row.createdOn) <= new Date(toDate)
      );

      setTotalProgression(filteredData.length);
    }
  };



  // account api 

  const fetchAccount = async () => {
    const result = await apiget(userRole === 'admin' ? `lead/list` : '');

    if (result && result.status === 200) {
      const filteredData = result?.data?.result.filter(
        (row) =>
          row.coordinatorName === Nameuser &&
          new Date(row.createdOn) >= new Date(fromDate) &&
          new Date(row.createdOn) <= new Date(toDate)
      );

      setTotalAccount(filteredData.length);
    }
  };




  // fund api

  const fetchFund = async () => {
    const result = await apiget(userRole === 'admin' ? `lead/list` : '');

    if (result && result.status === 200) {
      const filteredData = result?.data?.result.filter(
        (row) =>
          row.fundAddedBy === Nameuser &&
          new Date(row.createdOn) >= new Date(fromDate) &&
          new Date(row.createdOn) <= new Date(toDate)
      );

      setTotalFund(filteredData.length);
    }
  };




  //  full trade api


  const fetchfulltrade = async () => {
    const result = await apiget(userRole === 'admin' ? `lead/list` : '');

    if (result && result.status === 200) {
      const filteredData = result?.data?.result.filter(
        (row) =>
          row.fullTradeBy === Nameuser&&
          new Date(row.createdOn) >= new Date(fromDate) &&
          new Date(row.createdAt) <= new Date(toDate)
      );

      setTotalFullTrade(filteredData.length);
    }
  };





  useEffect(() => {
    fetchprogression();
    fetchfulltrade();
    fetchAccount();
    fetchFund();
  }, [Nameuser, fromDate, toDate]);
    return (
        <>
            <form onSubmit={handleSubmit}>
                <TextField
                    label="Username"
                    value={Name}
                    onChange={(e) => setfirstName(e.target.value)}
                />
                
                
                <TextField
                    label=""
                    type="date"
                    value={fromDate}
                    onChange={(e) => setFromDate(e.target.value)}

                    
                    
                />
                <TextField
                    label=""
                    type="date"
                    value={toDate}
                    onChange={(e) => setToDate(e.target.value)}
                />
                <Button type="submit" variant="contained">Submit</Button>
            </form>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={3}>
                    
              

                
                

                <AppWidgetSummary title="Total First Trade" total={totalProgression} icon={'ic:baseline-leaderboard'} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <AppWidgetSummary title="Total Account Open" total={totalAccount}color="warning" icon={'ic:baseline-policy'} />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                    <AppWidgetSummary title="Total Full Trade" total={totalFullTrade}color="warning" icon={'ic:baseline-policy'} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <AppWidgetSummary title="Total Fund " total={totalFund}color="warning" icon={'ic:baseline-policy'} />
                </Grid>

               
            </Grid>
        </>

    );

};

export default UserProgressionForm;
